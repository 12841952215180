import $ from 'jquery';
class Modal {
  constructor($, element) {
    this.element = element;
    this.$element = $(element);
    this.$modalTrigger = $(
      '[data-trigger="' + $(element).data('modalTrigger') + '"]'
    );
    this.$close = $(element).find('[data-behavior="modal-close"]');
    this.$back = $(element).find('[data-behavior="modal-back"]');
    this.$content = $(element).find('[data-behavior="modal-content"]');
    this.$contentActions = $(element).find(
      '[data-behavior="modal-content-actions"]'
    );
    this.$window = $(window);
    this.$document = $(document);
  }

  init($) {
    this.$modalTrigger.on('click', e => {
      e.preventDefault();
      this.openModal($);
    });

    this.$close.on('click', () => {
      this.closeModal($);
    });

    this.$back.on('click', e => {
      e.preventDefault();
      this.closeModal($);
    });

    this.$document.on('keyup.modal', e => {
      // TAB
      if (e.which === 9) {
        if (this.$element.find(':focus').length) {
          this.openModal($);
        } else {
          this.closeModal($);
        }
      }
    });

    this.$document.on('click', e => {
      if (
        this.$element.hasClass('modal--open') &&
        $(e.target).closest('.modal').length <= 0
      ) {
        this.closeModal($);
      }
    });

    this.$document.on('keydown.modal', e => {
      // Escape
      if (e.which === 27 && this.$element.hasClass('modal--open')) {
        this.closeModal($);
      }
    });

    this.$element.on('transitionend', e => {
      if (e.target === this.element) {
        this.$element.removeClass('modal--transitioning');
      }
    });
  }

  focusSearchBar($) {
    if (this.$element.find('.search-bar ').length > 0) {
      this.$element.find('.search-bar__input').focus();
    }
  }

  closeModal($) {
    if (this.$element.hasClass('modal--open')) {
      this.$element.removeClass('modal--open').addClass('modal--transitioning');
      PubSub.publish('modalClosed', this.$element.data('modalTrigger'));
    }
  }

  openModal($) {
    if (!this.$element.hasClass('modal--open')) {
      this.$element.show();
      this.focusSearchBar();
      setTimeout(() => {
        this.$element.addClass('modal--transitioning modal--open');
        PubSub.publish('modalOpened', this.$element.data('modalTrigger'));
      }, 10);
    }
  }
}

(function ($) {
  $('[data-behavior="modal"]').each((index, element) => {
    const modal = new Modal($, element);
    modal.init($);
  });

  $(window).on('load', function () {
    $(".modal").delay(3000).removeClass("visually-hidden");
  });

  // Check if Navigator is Internet Explorer 11
  if (navigator.userAgent.indexOf('MSIE') !== -1 ||
    navigator.appVersion.indexOf('Trident/') > -1) {
    $('.modal__content-actions').addClass("sticky-bottom-ie");
  }
})($);