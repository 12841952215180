function throttle(type, name, obj = window) {
  let running = false;

  let func = () => {
    if (running) {
      return;
    }

    running = true;

    requestAnimationFrame(() => {
      obj.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };

  obj.addEventListener(type, func);
}

function isLargeDesktop() {
  return window.matchMedia('(min-width: 120em)').matches;
}

function isDesktop() {
  return window.matchMedia('(min-width: 90em)').matches;
}

function isLaptop() {
  return window.matchMedia('(min-width: 64em)').matches;
}

function isTablet() {
  return window.matchMedia('(min-width: 48em)').matches;
}
class NavParent {
  constructor($, element) {
    this.$navElement = $(element);
    this.$secondaryNav = $(element).next('.link-list');
    this.$secondaryNavLinks = $(element).next('.link-list').find('a');
    this.isMobile = false;
  }

  init($) {
    if (!isTablet()) {
      this.isMobile = true;
      this.bindNavClick($);
    }

    throttle('resize', 'resize.navparent');
    $(window).on('resize.navparent', () => {
      if (!this.isMobile && !isTablet()) {
        this.isMobile = true;
        this.bindNavClick($);
      } else if (this.isMobile && isTablet()) {
        this.isMobile = false;
        this.unbindNavClick($);
      }
    });
  }

  bindNavClick($) {
    this.$secondaryNav.prepend(this.$navElement.clone().attr('data-behavior', 'back'));

    this.$secondaryNav.find('[data-behavior="back"]').on('click.nav', (e) => {
      e.preventDefault();
      this.closeSecondaryNav($);
    });

    this.$navElement.on('click.nav', (e) => {
      e.preventDefault();
      this.openSecondaryNav($);
    });

    this.$secondaryNavLinks.on('focus.nav', () => {
      this.openSecondaryNav($);
    });

    this.$secondaryNavLinks.on('focusout.nav', () => {
      this.closeSecondaryNav($);
    });

    PubSub.subscribe('modalClosed', (e, trigger) => {
      if (this.$navElement.parents('[data-behavior="modal"]').data('modalTrigger') === trigger) {
        setTimeout(() => this.closeSecondaryNav($), 750);
      }
    });
  }

  unbindNavClick($) {
    this.$secondaryNav.find('[data-behavior="back"]').remove();
    this.$navElement.off('click.nav');
    this.$secondaryNavLinks.off('focus.nav');
    this.$secondaryNavLinks.off('focusout.nav');
  }

  closeSecondaryNav($) {
    this.$secondaryNav.scrollTop(0);
    this.$navElement.removeClass('nav__item--open');
  }

  openSecondaryNav($) {
    if (!this.$navElement.hasClass('nav__item--open')) {
      this.$navElement.addClass('nav__item--open');
    }
  }
}

jQuery(function ($) {
  $('[data-behavior="nav-parent"]').each((index, element) => {
    const navParent = new NavParent($, element);
    navParent.init($);
  });
});