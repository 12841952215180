import $ from 'jquery'

// get the sticky element
const stickyElm = document.querySelector('.sticky-menu')



const observer = new IntersectionObserver( 
  ([e]) => e.target.classList.toggle('stuck', e.intersectionRatio < 1),
  {rootMargin: '-1px 0px 0px 0px',threshold: [1]}
);

if (stickyElm){
  observer.observe(stickyElm);
}



function throttle(type, name, obj = window) {
  let running = false;

  let func = () => {
    if (running) {
      return;
    }

    running = true;

    requestAnimationFrame(() => {
      obj.dispatchEvent(new CustomEvent(name));
      running = false;
    });
  };

  obj.addEventListener(type, func);
}

function isLargeDesktop() {
  return window.matchMedia('(min-width: 120em)').matches;
}

function isDesktop() {
  return window.matchMedia('(min-width: 90em)').matches;
}

function isLaptop() {
  return window.matchMedia('(min-width: 64em)').matches;
}

function isTablet() {
  return window.matchMedia('(min-width: 48em)').matches;
}

/*!
 * jQuery Mousewheel 3.1.13
 * Copyright OpenJS Foundation and other contributors
 */

( function( factory ) {
    if ( typeof define === "function" && define.amd ) {

        // AMD. Register as an anonymous module.
        define( [ "jquery" ], factory );
    } else if ( typeof exports === "object" ) {

        // Node/CommonJS style for Browserify
        module.exports = factory;
    } else {

        // Browser globals
        factory( jQuery );
    }
} )( function( $ ) {

    var toFix  = [ "wheel", "mousewheel", "DOMMouseScroll", "MozMousePixelScroll" ],
        toBind = ( "onwheel" in window.document || window.document.documentMode >= 9 ) ?
                    [ "wheel" ] : [ "mousewheel", "DomMouseScroll", "MozMousePixelScroll" ],
        slice  = Array.prototype.slice,
        nullLowestDeltaTimeout, lowestDelta;

    if ( $.event.fixHooks ) {
        for ( var i = toFix.length; i; ) {
            $.event.fixHooks[ toFix[ --i ] ] = $.event.mouseHooks;
        }
    }

    var special = $.event.special.mousewheel = {
        version: "3.1.12",

        setup: function() {
            if ( this.addEventListener ) {
                for ( var i = toBind.length; i; ) {
                    this.addEventListener( toBind[ --i ], handler, false );
                }
            } else {
                this.onmousewheel = handler;
            }

            // Store the line height and page height for this particular element
            $.data( this, "mousewheel-line-height", special.getLineHeight( this ) );
            $.data( this, "mousewheel-page-height", special.getPageHeight( this ) );
        },

        teardown: function() {
            if ( this.removeEventListener ) {
                for ( var i = toBind.length; i; ) {
                    this.removeEventListener( toBind[ --i ], handler, false );
                }
            } else {
                this.onmousewheel = null;
            }

            // Clean up the data we added to the element
            $.removeData( this, "mousewheel-line-height" );
            $.removeData( this, "mousewheel-page-height" );
        },

        getLineHeight: function( elem ) {
            var $elem = $( elem ),
                $parent = $elem[ "offsetParent" in $.fn ? "offsetParent" : "parent" ]();
            if ( !$parent.length ) {
                $parent = $( "body" );
            }
            return parseInt( $parent.css( "fontSize" ), 10 ) ||
                parseInt( $elem.css( "fontSize" ), 10 ) || 16;
        },

        getPageHeight: function( elem ) {
            return $( elem ).height();
        },

        settings: {
            adjustOldDeltas: true, // see shouldAdjustOldDeltas() below
            normalizeOffset: true  // calls getBoundingClientRect for each event
        }
    };

    $.fn.extend( {
        mousewheel: function( fn ) {
            return fn ? this.on( "mousewheel", fn ) : this.trigger( "mousewheel" );
        },

        unmousewheel: function( fn ) {
            return this.off( "mousewheel", fn );
        }
    } );


    function handler( event ) {
        var orgEvent   = event || window.event,
            args       = slice.call( arguments, 1 ),
            delta      = 0,
            deltaX     = 0,
            deltaY     = 0,
            absDelta   = 0;
        event = $.event.fix( orgEvent );
        event.type = "mousewheel";

        // Old school scrollwheel delta
        if ( "detail" in orgEvent ) {
            deltaY = orgEvent.detail * -1;
        }
        if ( "wheelDelta" in orgEvent ) {
            deltaY = orgEvent.wheelDelta;
        }
        if ( "wheelDeltaY" in orgEvent ) {
            deltaY = orgEvent.wheelDeltaY;
        }
        if ( "wheelDeltaX" in orgEvent ) {
            deltaX = orgEvent.wheelDeltaX * -1;
        }

        // Firefox < 17 horizontal scrolling related to DOMMouseScroll event
        if ( "axis" in orgEvent && orgEvent.axis === orgEvent.HORIZONTAL_AXIS ) {
            deltaX = deltaY * -1;
            deltaY = 0;
        }

        // Set delta to be deltaY or deltaX if deltaY is 0 for backwards compatabilitiy
        delta = deltaY === 0 ? deltaX : deltaY;

        // New school wheel delta (wheel event)
        if ( "deltaY" in orgEvent ) {
            deltaY = orgEvent.deltaY * -1;
            delta  = deltaY;
        }
        if ( "deltaX" in orgEvent ) {
            deltaX = orgEvent.deltaX;
            if ( deltaY === 0 ) {
                delta  = deltaX * -1;
            }
        }

        // No change actually happened, no reason to go any further
        if ( deltaY === 0 && deltaX === 0 ) {
            return;
        }

        // Need to convert lines and pages to pixels if we aren't already in pixels
        // There are three delta modes:
        //   * deltaMode 0 is by pixels, nothing to do
        //   * deltaMode 1 is by lines
        //   * deltaMode 2 is by pages
        if ( orgEvent.deltaMode === 1 ) {
            var lineHeight = $.data( this, "mousewheel-line-height" );
            delta  *= lineHeight;
            deltaY *= lineHeight;
            deltaX *= lineHeight;
        } else if ( orgEvent.deltaMode === 2 ) {
            var pageHeight = $.data( this, "mousewheel-page-height" );
            delta  *= pageHeight;
            deltaY *= pageHeight;
            deltaX *= pageHeight;
        }

        // Store lowest absolute delta to normalize the delta values
        absDelta = Math.max( Math.abs( deltaY ), Math.abs( deltaX ) );

        if ( !lowestDelta || absDelta < lowestDelta ) {
            lowestDelta = absDelta;

            // Adjust older deltas if necessary
            if ( shouldAdjustOldDeltas( orgEvent, absDelta ) ) {
                lowestDelta /= 10;
            }
        }

        // Adjust older deltas if necessary
        if ( shouldAdjustOldDeltas( orgEvent, absDelta ) ) {

            // Divide all the things by 40!
            delta  /= 10;
            deltaX /= 10;
            deltaY /= 10;
        }

        // Get a whole, normalized value for the deltas
        delta  = Math[ delta  >= 1 ? "floor" : "ceil" ]( delta  / lowestDelta );
        deltaX = Math[ deltaX >= 1 ? "floor" : "ceil" ]( deltaX / lowestDelta );
        deltaY = Math[ deltaY >= 1 ? "floor" : "ceil" ]( deltaY / lowestDelta );

        // Normalise offsetX and offsetY properties
        if ( special.settings.normalizeOffset && this.getBoundingClientRect ) {
            var boundingRect = this.getBoundingClientRect();
            event.offsetX = event.clientX - boundingRect.left;
            event.offsetY = event.clientY - boundingRect.top;
        }

        // Add information to the event object
        event.deltaX = deltaX;
        event.deltaY = deltaY;
        event.deltaFactor = lowestDelta;

        // Go ahead and set deltaMode to 0 since we converted to pixels
        // Although this is a little odd since we overwrite the deltaX/Y
        // properties with normalized deltas.
        event.deltaMode = 0;

        // Add event and delta to the front of the arguments
        args.unshift( event, delta, deltaX, deltaY );

        // Clearout lowestDelta after sometime to better
        // handle multiple device types that give different
        // a different lowestDelta
        // Ex: trackpad = 3 and mouse wheel = 120
        if ( nullLowestDeltaTimeout ) {
            window.clearTimeout( nullLowestDeltaTimeout );
        }
        nullLowestDeltaTimeout = window.setTimeout( nullLowestDelta, 200 );

        return ( $.event.dispatch || $.event.handle ).apply( this, args );
    }

    function nullLowestDelta() {
        lowestDelta = null;
    }

    function shouldAdjustOldDeltas( orgEvent, absDelta ) {

        // If this is an older event and the delta is divisable by 120,
        // then we are assuming that the browser is treating this as an
        // older mouse wheel event and that we should divide the deltas
        // by 40 to try and get a more usable deltaFactor.
        // Side note, this actually impacts the reported scroll distance
        // in older browsers and can cause scrolling to be slower than native.
        // Turn this off by setting $.event.special.mousewheel.settings.adjustOldDeltas to false.
        return special.settings.adjustOldDeltas && orgEvent.type === "mousewheel" &&
            absDelta % 120 === 0;
    }

} );

/* global jQuery */
const INLINE_CLASS = 'subnav__nav--inline';
const EXTENDED_CLASS = 'subnav__nav--extended';
const SECONDARY_CLASS = 'subnav__nav--secondary';
const OPEN_CLASS = 'subnav__nav--open';
const SEE_MORE_ICON = `<svg width="26px" height="6px" viewBox="0 0 26 6" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-labelledby="icon-see-more-title">
<title id='icon-see-more-title'>See more</title>
<g stroke="none" stroke-width="1" fill-rule="evenodd">
  <circle cx="3" cy="3" r="3"></circle>
  <circle cx="13" cy="3" r="3"></circle>
  <circle cx="23" cy="3" r="3"></circle>
</g>
</svg>`;
const CLOSE_ICON = `<svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 22 22">
<title id="close-icon-title">close</title>
  <g fill="none" stroke-linecap="round" stroke-width="3.06" transform="translate(.25 1.05)">
    <path d="M22.6565476,10.2 L-1.40654762,10.2" transform="rotate(45 10.625 10.2)"/>
    <path d="M2.2595837,18.4247615 L19.2747615,1.4095837"/>
  </g>
</svg>`;

class Subnav {
  constructor($, element) {
    this.$element = $(element);
    this.$list = this.$element.find('.menu');
    this.$items = this.$element.find('.menu-item');
    this.$title = this.$element.find('[data-behavior="subnav-title"]');
    this.$icon = this.$element.find('[data-behavior="subnav-icon"]');
    this.$trigger = this.$items.find('.secondary-trigger');
    this.$secondary = this.$items.find('.sub-menu');
    this.$window = $(window);
    this.secondaryOpen = false;
    this.isExtended = false;
    this.isOpen = false;
    this.extendedHeight = 0;
    this.secondaryOpenHeight = '11em';
    this.secondaryHeight = '5em';
  }

  init($) {
    this.calculateWidth($);

    // If the screen is too small to display the inline view then the opening
    // and closing of the subnav is controlled by clicking on the "title"
    this.$title.on('click keydown', (e) => {
      // If mouseclick or enter / space
      if (e.type === 'click' || e.which === 32 || e.which === 13) {
        if (this.isOpen) {
          this.closeList($);
        } else {
          this.openList($);
        }
      }
    });

    var windowWidth = $(window).width();

    throttle('resize', 'resize.subnav');
    this.$window.on('resize.subnav', () => {
      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
      if ($(window).width() != windowWidth) {

        windowWidth = $(window).width();
      
        this.calculateWidth($);
      }
    });

  }

  closeExtension($) {
    this.$element.removeClass(EXTENDED_CLASS);
    this.$icon.html(SEE_MORE_ICON);
    this.isExtended = false;
    this.$list.animate({
      height: this.$element.height()
    }, {
      complete: () => {
        this.$list.removeAttr('style');
      }
    });
  }

  openExtension($) {
    this.$element.addClass(EXTENDED_CLASS);
    this.$icon.html(CLOSE_ICON);
    this.isExtended = true;
    this.$list.animate({
      height: this.extendedHeight
    });
  }

  openSecondaryLevel($) {
    this.$element.addClass(SECONDARY_CLASS);
    this.secondaryOpen = true;
    this.$element.animate({
      height: this.secondaryOpenHeight
    });
  }

  closeSecondaryLevel($) {
    this.$element.removeClass(SECONDARY_CLASS);
    this.secondaryOpen = false;
    this.$element.animate({
      height: this.secondaryHeight
    }, {
      complete: () => {
        this.$element.removeAttr('style');
      }
    });
  }

  closeList($) {
    this.$element.removeClass(OPEN_CLASS);
    this.isOpen = false;
    this.$list.animate({
      height: 0
    }, {
      complete: () => {
        this.$list.removeAttr('style');
      }
    });
  }

  openList($) {
    this.$element.addClass(OPEN_CLASS);
    this.isOpen = true;
    this.$list.removeAttr('style').css('height', 'auto');
    const listHeight = this.$list.height();
    this.$list.height(0).animate({
      height: listHeight
    });
  }

  // Calculate the width of the subnav to see whether it should display in inline
  // or collapsed view.

  calculateWidth($) {
    if (this.isOpen) {
      this.closeList($);
    } else if (this.isExtended) {
      this.closeExtension($);
    }
    this.$element.addClass(INLINE_CLASS);
    this.$items.find('a').removeAttr('style').removeAttr('tabindex');
    this.$list.height(this.$element.height());
    var windowWidth = window.innerWidth;
    let threeItemsWidth = 0;
    let totalWidth = 0;
    const navHeight = this.$element.outerHeight();
    this.extendedHeight = navHeight;

    this.$items.each((index, item) => {
      const $item = $(item);
      const width = $item.outerWidth();
      if (index < 3) {
        threeItemsWidth += width;
      }
      totalWidth += width;
      
      if (windowWidth < 1024) {
        this.$element.removeClass(INLINE_CLASS);
        this.$list.removeAttr('style');
        this.$items.find('a').removeAttr('style').removeAttr('tabindex');
      } else {
        this.$element.addClass(INLINE_CLASS);
        this.$list.css('width', totalWidth + 40);
      }
    });

  }
}

// Initiate the subnav
(function($){
  $('[data-behavior="subnav"]').each((index, element) => {
    const subnav = new Subnav($, element);
    subnav.init($);
  });

})(jQuery);

// Check for child items and add child menu class to subnav
(function($){

  if ( $( ".subnav .sub-menu" ).length ) {
    $('.subnav').addClass('subnav--child-menu');

    var triggerButton = '<button class="secondary-trigger" aria-label="Expand child navigation" role="button"><svg xmlns="http://www.w3.org/2000/svg" width="17" height="8" viewBox="0 0 17 8" aria-hidden="true"><title>Chevron pointing down</title><polyline fill="none" stroke-linecap="round" stroke-linejoin="round" stroke-width="3" points=".135 .421 7 7.781 0 15.278" transform="matrix(0 1 1 0 .5 .5)"/></svg></button>';
    $('.subnav .sub-menu').before(triggerButton);
  }

})(jQuery);

// Trigger secondary navigation
(function($){

  $('.secondary-trigger').click(function () {
     event.preventDefault();
     event.stopPropagation();
     var totalSecondaryWidth = 0;

    // if the subnav is open and the menu item itself (so we can toggle between parent items)
    if ($('.page__wrapper').hasClass('subnav-children-open') && $(this).parent().hasClass('isOpen')) {
      $('.page__wrapper').removeClass('subnav-children-open');
    }
    else {
      $('.page__wrapper').addClass('subnav-children-open');

      // Click anywhere on the overlay to close subnav
      $('.page__wrapper').on('click', function(event) {
        // Make sure we can click anywhere inside the subnav
        if (!$(event.target).closest('.subnav--child-menu').length) {
          // Close everything up
          $('.page__wrapper').removeClass('subnav-children-open');
          $('.subnav--child-menu .menu-item-has-children').removeClass('isOpen');
          $('.subnav--child-menu .sub-menu').removeAttr("style");
          $('.subnav--child-menu .subnav__nav').removeClass('isExpanded');
          $(this).siblings().removeClass('isOpen');
        }
      });
    }

    $(this).parent().siblings().removeClass('isOpen');
    $('.subnav__child-menu').removeAttr("style");

    if ($(this).parent().hasClass('isOpen')) {
      $('.subnav--child-menu .menu-item-has-children').removeClass('isOpen');
      $('.subnav--child-menu .sub-menu').removeAttr("style");
      $('.subnav--child-menu .subnav__nav').removeClass('isExpanded');
      $(this).siblings().removeClass('isOpen');
    } 
    
    else {
      $('.subnav--child-menu .subnav__nav').addClass('isExpanded');
      $(this).parent().addClass('isOpen');
      $(this).next().css('display', 'block');
      $('.sub-menu a').each(function( index ) {
        totalSecondaryWidth += $(this).outerWidth( true );
      });
       $('.subnav__nav--inline .sub-menu').css('width', totalSecondaryWidth + 500);
    }
  });

})(jQuery);

// Enable mousewheel scroll
(function($){

    $(window).on("load", (function () {
      $('.subnav__nav--inline').mousewheel(function(e, delta) {
        this.scrollLeft -= (delta * 2);
        e.preventDefault();
      });  
    }));

})(jQuery);


// Enable drag and scroll
(function($){

    const slider = document.querySelector('.subnav__nav--inline');
    let isDown = false;
    let startX;
    let scrollLeft;

    if(slider) {
        slider.addEventListener('mousedown', (e) => {
            isDown = true;
            slider.classList.add('grabbed');
            startX = e.pageX - slider.offsetLeft;
            scrollLeft = slider.scrollLeft;
        });
        slider.addEventListener('mouseleave', () => {
            isDown = false;
            slider.classList.remove('grabbed');
        });
        slider.addEventListener('mouseup', () => {
            isDown = false;
            slider.classList.remove('grabbed');
        });
        slider.addEventListener('mousemove', (e) => {
            if(!isDown) return;
            e.preventDefault();
            const x = e.pageX - slider.offsetLeft;
            const walk = (x - startX) * 2; //scroll speed
            slider.scrollLeft = scrollLeft - walk;
        });
    }

})(jQuery);


(function($) {
  // duration of scroll animation
  var scrollDuration = 300;
  // paddles
  var paddleMargin = 32;
  var leftPaddle = document.getElementsByClassName('left-paddle');
  var rightPaddle = document.getElementsByClassName('right-paddle');
  // get items dimensions
  var itemsLength = $('.subnav__nav .menu').length;
  var itemSize = $('.subnav__nav .menu').outerWidth(true);

  // get wrapper width
  var getMenuWrapperSize = function () {
    return $('.subnav__wrapper').outerWidth();
  };

  var menuWrapperSize = getMenuWrapperSize();
  //console.log('Menu wrapper size: ' + menuWrapperSize);

  // get total width of all menu items
  var getMenuSize = function () {
    return itemsLength * itemSize;
  };
  var menuSize = getMenuSize();
  //console.log('Menu size: ' + menuSize);

  // get how much of menu is invisible
  var getmenuInvisibleSize = function () {
    return menuSize - menuWrapperSize;
  };
  var menuInvisibleSize = getmenuInvisibleSize();

  // the wrapper is responsive
  $(window).on('resize', function () {
    menuWrapperSize = getMenuWrapperSize();
    menuInvisibleSize = getmenuInvisibleSize();

    if (menuInvisibleSize > 60) {
      $(rightPaddle).removeClass('hidden');
    }
  });

  // get how much have we scrolled to the left
  var getMenuPosition = function () {
    return $('.subnav__nav').scrollLeft();
  };

  // finally, what happens when we are actually scrolling the menu
  $('.subnav__nav').on('scroll', function () {

    /// get how much of menu is invisible
    menuInvisibleSize = menuSize - menuWrapperSize;

    // get how much have we scrolled so far
    var menuPosition = getMenuPosition();
    var menuEndOffset = menuInvisibleSize - paddleMargin;

    // show & hide the paddles 
    // depending on scroll position
    if (menuPosition <= paddleMargin) {
      $(leftPaddle).addClass('hidden'); 
     $(rightPaddle).removeClass('hidden');
    } else if (menuPosition < menuEndOffset) {
      // show both paddles in the middle
      $(leftPaddle).removeClass('hidden');
      $(rightPaddle).removeClass('hidden');
    } else if (menuPosition >= menuEndOffset) {
      $(leftPaddle).removeClass('hidden');
      $(rightPaddle).addClass('hidden');
    }
  });

  if (menuInvisibleSize > 50) {
    $(rightPaddle).removeClass('hidden');
  }

  $(rightPaddle).on('click', function(e) {
    $('.subnav__nav--inline').animate( { scrollLeft: '+=' + itemSize }, scrollDuration);
    $(leftPaddle).removeClass('hidden');
  });

  // scroll to right
  $(leftPaddle).on('click', function() {
    $('.subnav__nav--inline').animate({ scrollLeft: '-=' + itemSize }, scrollDuration);
  });

})(jQuery);

// Load mobile menu after page is loaded due to order of DOM elements
(function($){
    $(window).on("load", (function () {
       $('.subnav__title').removeAttr("style");
    }));
})(jQuery);