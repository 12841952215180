var cm;
var cmHome;

document.addEventListener('DOMContentLoaded', function() {
  cm = document.querySelector('.context-nav'); 
  cmHome = getOffsetTop(cm);   
});

window.addEventListener(  "scroll", function(ev) {
  elementShouldBeFixed(cm);
  // console.log("cm: " + cmHome);
  // console.log("window" + window.pageYOffset);
});

function elementShouldBeFixed(cm) {
  if (cm) {            
    if (cmHome < window.pageYOffset ) {
      cm.classList.add("fix-to-top");
    } else {
      cm.classList.remove("fix-to-top");
    }
  }
}

const getOffsetTop = element => {
  let offsetTop = 0;
  while(element) {
    offsetTop += element.offsetTop;
    element = element.offsetParent;
  }
  return offsetTop;
}