import $ from 'jquery'

class BlockVideoOembed extends window.HTMLDivElement {
  constructor (...args) {
    const self = super(...args)
    self.init()
    return self
  }

  init () {
    this.$ = $(this)
    this.resolveElements()
    this.bindFunctions()
    this.bindEvents()

    $('.video').hover(function () {
      $('.media__annotation.media__annotation-layered').addClass(
        'video-and-tour-hover'
      )
    })
  }

  resolveElements () {
    this.$posterImage = $('.figure-image', this)
    this.$videoPlayer = $('.video-player', this)
    this.$iframe = $('iframe', this)
  }

  bindFunctions () {
    this.loadVideo = this.loadVideo.bind(this)
    this.videoIsLoaded = this.videoIsLoaded.bind(this)
  }

  bindEvents () {
    this.$.one('click', '.video-playButton', this.loadVideo)
  }

  loadVideo () {
    this.$iframe.one('load', this.videoIsLoaded.bind(this))
    this.$iframe.attr('src', this.$iframe.data('src'))
    this.$videoPlayer.addClass('video-player--isLoading')
  }

  videoIsLoaded () {
    this.$videoPlayer.removeClass('video-player--isLoading')
    this.$videoPlayer.addClass('video-player--isLoaded')
    this.$posterImage.addClass('figure-image--isHidden')
  }
}

var youkuVids = document.querySelectorAll('.youku-video')
if (youkuVids) {
  youkuVids.forEach((el) => {
    var src = el.dataset.src
    src = src.substring(src.lastIndexOf('id_') + 3, src.lastIndexOf('.html'))
    if (src.endsWith('==')) {
      // Remove '==' from the end
      src = src.slice(0, -2)

    }
    console.group(src)
    el.setAttribute('src', 'https://player.youku.com/embed/' + src)
  })
}

window.customElements.define('flynt-block-video-oembed', BlockVideoOembed, {
  extends: 'div'
})
