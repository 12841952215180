class Accordion {
  constructor($, element) {
    this.$element = $(element);
    this.$button = $(element).find('.accordion__button');
    this.$content = $(element).find('.accordion__content');
  }

  init($) {
    this.$button.on('click', e => {
      e.preventDefault();
      this.toggleAccordion($, this.$element);
    });
  }

  toggleAccordion($, target) {
    target.toggleClass('accordion__section--expanded');
    const pressed = this.$button.attr('aria-expanded') === 'true';
    this.$button.attr('aria-expanded', !pressed);
    this.$content.attr('aria-hidden', !!pressed);
  }
}

(function ($) {
  const accordionState = (e, state) => {
    let text = state ? e.target.dataset.openText : e.target.dataset.closeText ;
    $(e.target).text(`${text}`);
    $(e.target)
      .closest('.accordion')
      .find('.accordion__button')
      .attr('aria-expanded', state)
      .trigger('click');
    $(e.target)
      .closest('.accordion')
      .find('.accordion__section')[!state ? 'addClass' : 'removeClass']('accordion__section--expanded');
  };

  $('[data-behavior="accordion"]').each((lib, element) => {
    const accordion = new Accordion($, element);
    accordion.init($);
  });

  $('[data-behavior="accordion-all"]').on('click', e => {
    const active = $(e.target).attr('aria-expanded') === 'true';
    $(e.target).attr('aria-expanded', !active);
    if (active) {
      accordionState(e, true);
    } else {
      accordionState(e, false);
    }
  });

})(jQuery);