import $ from 'jquery'

class Gallery {
  constructor($, element, imageCount) {
    this.$element = $(element);
    this.imageCount = imageCount;
    this.indexOnLoad = this.$element.data('modaal-index');
  }

  init($) {
    $(this.$element).modaal({
      type: 'image',
      close_text: `Close <svg width="22" height="22" aria-labelledby="svg-close-icon">
  <g fill="#ffffff" stroke-linecap="round" stroke-width="3.06">
    <path d="M19.3825889 19.7575889L2.36741109 2.74241109M2.5095837 19.4747615L19.5247615 2.4595837"/>
  </g>
</svg>`,
      outer_controls: true,
      after_open: () => {
        this.createCounter($);
      },
      after_image_change: current_item => {
        this.setCounter($, current_item);
      }
    });
  }

  createCounter($) {
    $('.modaal-container').prepend(`
      <div class="modaal-counter">${this.indexOnLoad}/${this.imageCount}</div>
    `);
  }

  setCounter($, current_item) {
    const currentIndex = parseInt(current_item[0].classList[1].match(/(\d+)$/)[0], 10);
    $('.modaal-counter').text(`${currentIndex + 1}/${this.imageCount}`);
  }
}

(function ($) {
  $('[data-behavior="gallery"]').each((index, element) => {
    let imageCount = $(element)
      .find('.gallery__inside')
      .children().length;
    $('[data-behavior="gallery_image"]').each((index, element) => {
      const gallery = new Gallery($, element, imageCount);
      gallery.init($);
    });
  });
})($);